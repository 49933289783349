import { colors } from "@/styles/global.styles";
import { NMSReply } from "@/types/messaging";
import { getLocalUser } from "@/utils/helpers/localstorage";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Color from "color";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useReducer, useRef, useState } from "react";
import toast from "react-hot-toast";
import Webcam from "react-webcam";
import { useOnClickOutside } from "usehooks-ts";
import BodyPortal from "./BodyPortal";
import { overlayTransition } from "./DraggableOverlay";
import { ThreeLinesLoader } from "./Loaders/ThreeLines";

const extension = "jpeg";
const mimetype = `image/${extension}`;

type FullscreenWebcamProps = {
  onPhoto: (file: File, reply?: NMSReply) => Promise<void> | void;
  closeCamera: () => void;
  open: boolean;
  reply?: NMSReply;
};
function FullscreenWebcam(props: FullscreenWebcamProps) {
  return (
    <AnimatePresence>
      {props.open && <InnerFullscreenWebcam {...props} />}
    </AnimatePresence>
  );
}

function InnerFullscreenWebcam({
  onPhoto,
  closeCamera,
  reply,
}: FullscreenWebcamProps) {
  const [cameraLoaded, setCameraLoaded] = useReducer(() => true, false);
  const [cameraLoadError, setCameraLoadError] = useReducer(() => true, false);
  const sendInProgressRef = useRef(false);

  useEffect(() => {
    if (!cameraLoadError) return;

    toast.error("Unable to load camera!");
    closeCamera();
  }, [cameraLoadError]);

  const wrapperRef = useRef<HTMLDivElement>(null!);
  const previewWrapperRef = useRef<HTMLDivElement>(null);
  const cameraRef = useRef<Webcam>(null!);

  useOnClickOutside(
    wrapperRef,
    (e) => {
      e.stopPropagation();
      if (
        wrapperRef.current?.contains(e.target as Node) ||
        previewWrapperRef.current?.contains(e.target as Node)
      ) {
        return;
      }
      closeCamera();
    },
    "mouseup"
  );

  const [previewPhoto, setPreviewPhoto] = useState<string | null>();

  useEffect(() => {
    if (!cameraRef.current) return;

    const closeCameraStream = () => {
      const videoStream = cameraRef.current?.stream;
      for (const track of videoStream?.getVideoTracks() || []) track.stop();
      closeCamera();
    };

    window.addEventListener("beforeunload", closeCameraStream);

    return () => {
      window.removeEventListener("beforeunload", closeCameraStream);
    };
  }, [cameraRef]);

  if (cameraLoadError) {
    return null;
  }

  const getPreviewPhoto = () => {
    const screenshot = cameraRef.current.getScreenshot();
    if (!screenshot) {
      console.error("Unable to take a screenshot of the video preview!");
      return;
    }
    setPreviewPhoto(screenshot);
  };
  const resetPreviewPhoto = () => {
    setPreviewPhoto(null);
  };

  const takePhoto = async () => {
    if (!previewPhoto) return;

    if (sendInProgressRef.current) {
      return;
    }

    sendInProgressRef.current = true;

    const imageBlob = await (await fetch(previewPhoto)).blob();
    const fileName = `photo_${getLocalUser()}_${new Date().getTime()}.${extension}`;
    const file = new File([imageBlob], fileName, { type: mimetype });
    onPhoto(file, reply);
    closeCamera();
  };

  return (
    <BodyPortal>
      <motion.div
        key="camera"
        css={{
          zIndex: "9999",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgb(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={overlayTransition}
      >
        <div
          style={!cameraLoaded ? { display: "none" } : undefined}
          css={{
            position: "absolute",
            width: "80vw",
            maxWidth: "min(90vw, 70em)",
            height: "auto",
            maxHeight: "min(70vh, 50em)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            animation: `${keyframes({
              "0%": {
                opacity: "0",
              },
              "100%": {
                opacity: "1",
              },
            })} 0.25s ease`,
          }}
        >
          <div
            ref={wrapperRef}
            css={{ position: "relative" }}
            style={previewPhoto ? { display: "none" } : undefined}
          >
            <Webcam
              ref={cameraRef}
              css={{
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0 0 6px 0 rgb(0,0,0,0.5)",
              }}
              onUserMedia={setCameraLoaded}
              onUserMediaError={setCameraLoadError}
              screenshotFormat={mimetype}
              videoConstraints={{
                aspectRatio: {
                  ideal: 4 / 3,
                },
              }}
            />
            <WebcamButton
              css={{
                position: "absolute",
                bottom: "1em",
                transform: "translateX(-50%)",
                left: "50%",
              }}
              onClick={(e) => {
                e.stopPropagation();
                getPreviewPhoto();
              }}
            >
              <PhotoCameraIcon />
            </WebcamButton>
            <WebcamButton
              css={{
                position: "absolute",
                top: "1em",
                right: "1em",
              }}
              onClick={closeCamera}
            >
              <CloseIcon />
            </WebcamButton>
          </div>
          <AnimatePresence>
            {previewPhoto && (
              <motion.div
                key="preview"
                ref={previewWrapperRef}
                css={{ position: "absolute" }}
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={overlayTransition}
              >
                <img
                  src={previewPhoto}
                  css={{
                    width: "100%",
                    borderRadius: "8px",
                    boxShadow: "0 0 6px 0 rgb(0,0,0,0.5)",
                  }}
                />
                <div
                  css={{
                    position: "absolute",
                    bottom: "1em",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2em",
                  }}
                >
                  <WebcamButton onClick={resetPreviewPhoto}>
                    <CloseIcon />
                  </WebcamButton>
                  <WebcamButton onClick={takePhoto}>
                    <CheckIcon />
                  </WebcamButton>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {!cameraLoaded && <ThreeLinesLoader />}
      </motion.div>
    </BodyPortal>
  );
}

const WebcamButton = styled.button({
  width: "2.75em",
  height: "2.75em",
  borderRadius: "50%",
  background: new Color(colors.secondaryBackground).alpha(0.6).toString(),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  transition: "background 0.5s ease",
  ":hover": {
    background: new Color(colors.secondaryBackground).alpha(0.8).toString(),
  },
});

export default FullscreenWebcam;
