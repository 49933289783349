import twitterSpriteSheetURL from "@/assets/emoji/twitter-emoji-spritesheet-64.webp";
import InvitePopup from "@/components/shared/InvitePopup";
import { LatLng } from "@/components/shared/Map";
import { NMSReply, SendFileError, SendMessageResult } from "@/types/messaging";
import { preloadImage } from "@/utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import { checkMicPermissions } from "@/utils/helpers/mediaStream";
import Conversation from "@/utils/messaging/conversation/Conversation";
import {
  conversationsState,
  getSelectedConversationId,
  useSelectedConversation,
} from "@/utils/messaging/conversation/ConversationState";
import DeleteIcon from "@mui/icons-material/Delete";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SendIcon from "@mui/icons-material/Send";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { useAtom, useAtomValue } from "jotai";
import { nanoid } from "nanoid";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { colors } from "../../../styles/global.styles";
import { sendIsTyping } from "../../../utils/messaging";
import { IconButton } from "../../shared/Button";
import { VerticalRule } from "../../shared/VerticalRule";
import VoiceNote from "../chat/components/VoiceNote";
import ChatFooterDropDown from "./ChatFooterDropdown";
import { footerInputHeight, footerPaddingHeight } from "./ChatFooterUtils";
import EmojiPickerMenu from "./EmojiPicker";
import PersistentMenu from "./PersistentMenu";
import { TextArea, TextAreaAPI } from "./TextArea";

let spriteSheetPreloaded = false;

const ChatFooter = ({
  ref,
  onConversationStarted,
  contacts,
  onGetGroupChatSubject,
  onGetGroupChatIconUrl,
  onGroupChatCreationInProgress,
  isRcs = true,
  isConversationStarted = true,
  autoFocusInput = true,
  isOnOverlay = false,
}: {
  onConversationStarted?: (conversationId: string) => void;
  contacts?: WebGwContact[];
  onGetGroupChatSubject?: () => string | undefined;
  onGetGroupChatIconUrl?: () => Promise<string | undefined>;
  onGroupChatCreationInProgress?: () => void;
  isRcs?: boolean;
  isConversationStarted?: boolean;
  autoFocusInput?: boolean;
  isOnOverlay?: boolean;
} & {
  ref?: React.RefObject<ChatFooterRef | null>;
}) => {
  const isGroupChatCreation = !!onGroupChatCreationInProgress;
  const [reply, setReply] = useAtom(atoms.messaging.messageReply);
  const conversation = useSelectedConversation({
    enabled: !isGroupChatCreation,
  });
  const [voiceNoteRecordingBlob, setVoiceNoteRecordingBlob] = useState<
    Blob | undefined
  >(undefined);

  const lastInputRef = useRef("");

  const textAreaRef = useRef<TextAreaAPI>(null);

  const [showVoiceNoteRecording, setShowVoiceNoteRecording] = useState(false);
  const isConversationStartedRef = useRef(false);
  const [isShowInvitePopup, setShowInvitePopup] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [emojiPickerWasOpened, setEmojiPickerWasOpened] = useState(false);
  const toggleEmojiPicker = useCallback(() => {
    setEmojiPicker((prev) => {
      const res = !prev;
      if (res) {
        setEmojiPickerWasOpened(true);
      }
      return res;
    });
  }, []);
  const emojiMenuRef = useRef<HTMLDivElement>(null);

  const handleEmojiClick = useCallback(
    (emoji: string) => {
      if (!textAreaRef.current) return;

      textAreaRef.current.insertEmoji(emoji);
    },
    [textAreaRef.current]
  );

  const emojiMenuButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!emojiPicker) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiMenuRef.current &&
        !emojiMenuRef.current.contains(event.target as globalThis.Node) &&
        emojiMenuButtonRef.current &&
        !emojiMenuButtonRef.current.contains(event.target as globalThis.Node)
      ) {
        toggleEmojiPicker();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPicker]);

  useEffect(() => {
    if (spriteSheetPreloaded) return;
    spriteSheetPreloaded = true;
    const timeout = setTimeout(preloadImage, 1500, twitterSpriteSheetURL);
    return () => clearTimeout(timeout);
  }, []);

  const toggleShowInvitePopup = () => {
    setShowInvitePopup(!isShowInvitePopup);
  };

  useImperativeHandle(ref, () => ({
    sendMessage: () => {
      handleSendMessage();
    },
    focus: () => textAreaRef.current?.focus(),
    handleSendFile,
    setText: (s: string) => {
      textAreaRef.current?.setText(s);
    },
  }));

  const handleTyping = async (typing: boolean) => {
    if (!conversation || !textAreaRef.current) return;

    await sendIsTyping(
      !isGroupChatCreation && typing,
      conversation.id,
      conversation.getIsGroupChat(),
      conversation.getConferenceUri()
    );
  };

  const handleSendLocation = async (coordinates: LatLng, reply?: NMSReply) => {
    const conversationToUse = getConversationToUse();

    if (!conversationToUse) {
      return;
    }

    if (!isRcs) {
      setShowInvitePopup(true);
      return;
    }

    const send = async (groupIconUrl?: string) => {
      const conversationId = conversationToUse.id;
      handleConversationStarted(conversationId);

      const sendMessageResult = await (isGroupChatCreation
        ? conversationToUse.createGroupChatAndSendLocation(
            coordinates.lat,
            coordinates.lng,
            onGetGroupChatSubject?.(),
            groupIconUrl
          )
        : conversationToUse.sendLocation(
            coordinates.lat,
            coordinates.lng,
            reply
          ));

      updateConversationId(
        sendMessageResult?.contributionId || conversationToUse.id,
        conversationId
      );
    };

    await sendMessage(send);
  };

  const handleSendFile = async (file: File, reply?: NMSReply) => {
    const conversationToUse = getConversationToUse();
    if (!conversationToUse) {
      return;
    }

    if (!isRcs) {
      setShowInvitePopup(true);
      return;
    }

    const send = async (groupIconUrl?: string) => {
      const conversationId = conversationToUse.id;
      handleConversationStarted(conversationId);

      const sendMessageResult = await (isGroupChatCreation
        ? conversationToUse.createGroupChatAndSendFile(
            file,
            onGetGroupChatSubject?.(),
            groupIconUrl
          )
        : conversationToUse.sendFile(file, reply));

      if (sendMessageResult === SendFileError.TOO_BIG) {
        toast.error(
          "Failed to send file: your message is too big compared to the network requirements."
        );
      }

      updateConversationId(
        (sendMessageResult as SendMessageResult)?.contributionId ||
          conversationToUse.id,
        conversationId
      );
    };

    await sendMessage(send);
  };

  const handleSendMessage = (reply?: NMSReply | null) => {
    const text = textAreaRef.current?.getText();

    console.log(
      "handleSendMessage():",
      `text="${text}" voiceNote=${!voiceNoteRecordingBlob}`
    );

    if (text?.trim().length === 0 && !voiceNoteRecordingBlob) return;

    const conversationToUse = getConversationToUse();

    if (!conversationToUse) {
      return;
    }

    if (!isRcs) {
      setShowInvitePopup(true);
      return;
    }

    const send = async (groupIconUrl?: string) => {
      const conversationId = conversationToUse.id;
      handleConversationStarted(conversationId);

      let sendMessageResult: SendFileError | SendMessageResult | undefined;
      if (voiceNoteRecordingBlob) {
        const voiceNote = new File(
          [voiceNoteRecordingBlob],
          `voice_${nanoid(4)}_${new Date().getTime()}.aac`,
          {
            type: voiceNoteRecordingBlob.type,
          }
        );
        if (isGroupChatCreation) {
          sendMessageResult =
            await conversationToUse.createGroupChatAndSendFile(
              voiceNote,
              onGetGroupChatSubject?.(),
              groupIconUrl
            );
        } else {
          sendMessageResult = await conversationToUse.sendFile(
            voiceNote,
            reply ?? undefined
          );
        }
      } else {
        if (isGroupChatCreation) {
          sendMessageResult =
            await conversationToUse.createGroupChatAndSendTextMessage(
              text!,
              onGetGroupChatSubject?.(),
              groupIconUrl
            );
        } else {
          sendMessageResult = await conversationToUse.sendTextMessage(
            text!,
            false,
            reply || undefined
          );
        }
      }

      if (sendMessageResult === SendFileError.TOO_BIG) {
        toast.error(
          "Failed to send file: your message is too big compared to the network requirements."
        );
      }
      setVoiceNoteRecordingBlob(undefined);
      setShowVoiceNoteRecording(false);

      updateConversationId(
        (sendMessageResult as SendMessageResult)?.contributionId ||
          conversationToUse.id,
        conversationId
      );
    };

    textAreaRef.current?.clear();
    setEmojiPicker(false);
    void sendMessage(send);
  };

  /**
   * When in group chat creation, we make the process non blocking as it could take a few seconds for the group to be created (mostly if need to upload group icon first)
   * In this case, the group chat screen will be in loading mode and the footer disabled until we get response for the fetch icon part
   */
  const sendMessage = async (
    send: (groupIconUrl?: string) => Promise<void>
  ) => {
    let iconUrl: string | undefined = undefined;
    if (isGroupChatCreation) {
      onGroupChatCreationInProgress?.();
      setDisabled(true);

      if (onGetGroupChatIconUrl) {
        // Fetching the icon should not be blocking, as soon as we get it we switch to the conversation, this will allow to see the send message directly from the conversation in case it takes time, for example when creating a group chat by sending a file
        iconUrl = await onGetGroupChatIconUrl();
      }
    }

    await send(iconUrl);

    setReply(null);
  };

  const handleConversationStarted = (conversationId: string) => {
    if (
      !isConversationStartedRef.current ||
      getSelectedConversationId() !== conversationId
    ) {
      isConversationStartedRef.current = true;
      onConversationStarted?.(conversationId);
    }
  };

  const updateConversationId = (
    conversationId: string,
    oldConversationId?: string
  ) => {
    // For group chat creation, we locally generate a conversation id before getting the real one from the network, we need to delete the old conversation from in-memory db
    if (oldConversationId && oldConversationId !== conversationId) {
      // Redirect to the new conversation if we are already in the old one
      if (oldConversationId === getSelectedConversationId()) {
        onConversationStarted?.(conversationId);
      }
      conversationsState.conversations.delete(oldConversationId);
    }
  };

  const getConversationToUse = () => {
    const conversationToUse = isGroupChatCreation
      ? Conversation.createTempForGroupChat(contacts!)!.conversation
      : conversation;

    if (!conversationToUse) {
      console.error("No conversation selected?!?");
    }

    return conversationToUse;
  };

  const micPermitted = useRef(false);
  const toggleVoiceNoteRecording = async () => {
    const show = !showVoiceNoteRecording;

    if (show) {
      micPermitted.current ||= await checkMicPermissions();
      if (micPermitted.current) {
        setShowVoiceNoteRecording(true);
      } else {
        toast.error("You need to enable microphone permission in your browser");
      }
    } else {
      setShowVoiceNoteRecording(false);
      setVoiceNoteRecordingBlob(undefined);
    }
  };

  const contact = conversation?.participants[0];

  // Not recording or audio already got
  const canSendMessage =
    isGroupChatCreation || !showVoiceNoteRecording || voiceNoteRecordingBlob;

  const sendBtnDisabled = useAtomValue(atoms.messaging.sendBtnDisabled);

  return (
    <div
      css={{
        width:
          isGroupChatCreation || isOnOverlay
            ? "100%"
            : `calc(100% - ${footerPaddingHeight} * 2)`,
        position: "relative",
        marginBottom: isOnOverlay ? "0" : footerPaddingHeight,
        display: "flex",
        gap: "1em",
        boxShadow: reply !== null ? "0 0 10px rgba(0, 0, 0, 0.2)" : undefined,
        zIndex: 2,
      }}
    >
      <div
        css={{
          width: "100%",
          minHeight: footerInputHeight,
          display: "flex",
          alignItems: "center",
          backgroundColor: colors.secondaryBackground,
          borderRadius: "10px",
          padding: "0 0.5em",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: "0.25em",
          }}
        >
          {(isGroupChatCreation || contact) && (
            <>
              {contact && <PersistentMenu contact={contact} />}
              {(isGroupChatCreation || (contact && !contact.isChatbot)) && (
                <>
                  <ChatFooterDropDown
                    isDisabled={disabled}
                    reply={reply ?? undefined}
                    isGroupChatCreation={isGroupChatCreation}
                    onSendFile={handleSendFile}
                    onSendLocation={handleSendLocation}
                  />

                  <IconButton
                    ref={emojiMenuButtonRef}
                    css={{
                      color: colors.secondaryTextColor,
                      "&:hover": { color: colors.primaryTextColor },
                    }}
                    style={
                      emojiPicker
                        ? { color: colors.primaryTextColor }
                        : undefined
                    }
                    onClick={toggleEmojiPicker}
                  >
                    <TagFacesIcon />
                  </IconButton>

                  {!showVoiceNoteRecording && (
                    <IconButton
                      disabled={disabled}
                      css={{
                        color: colors.secondaryTextColor,
                        "&:hover": { color: colors.primaryTextColor },
                      }}
                      onClick={toggleVoiceNoteRecording}
                    >
                      <MicNoneIcon />
                    </IconButton>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {showVoiceNoteRecording ? (
          <>
            <VoiceNote
              height={"2.5em"}
              onVoiceNoteRecorded={setVoiceNoteRecordingBlob}
            />

            <IconButton
              css={{
                color: "white",
                "&:hover": {
                  backgroundColor: "#393C41",
                  borderRadius: "100%",
                },
              }}
              onClick={toggleVoiceNoteRecording}
            >
              <DeleteIcon css={{ padding: "0.1em" }} />
            </IconButton>
          </>
        ) : (
          <TextArea
            ref={textAreaRef}
            disabled={disabled || (!isGroupChatCreation && !conversation)}
            handleTyping={handleTyping}
            handleSendMessage={handleSendMessage}
            lastInputRef={lastInputRef}
            autoFocus={isConversationStarted && autoFocusInput}
          />
        )}

        {canSendMessage && (
          <>
            <VerticalRule />
            <IconButton
              disabled={
                // Need either text or audio recorded
                (sendBtnDisabled && !voiceNoteRecordingBlob) ||
                (!isGroupChatCreation && !conversation)
              }
              css={{
                ":hover": {
                  color: colors.primaryAccentColor,
                },
                ":disabled": {
                  color: colors.secondaryTextColor,
                  cursor: "not-allowed",
                },
                marginLeft: "auto",
              }}
              onClick={() => {
                handleSendMessage(reply);
                setReply(null);
              }}
            >
              <SendIcon />
            </IconButton>
          </>
        )}
        {isShowInvitePopup && (
          <InvitePopup togglePopup={toggleShowInvitePopup} />
        )}
        {emojiPickerWasOpened && (
          <EmojiPickerMenu
            ref={emojiMenuRef}
            handleEmojiClick={handleEmojiClick}
            visible={emojiPicker}
          />
        )}
      </div>
    </div>
  );
};

export type ChatFooterRef = {
  sendMessage: () => void;
  focus: () => void;
  handleSendFile: (file: File, reply?: NMSReply) => void;
  setText: (s: string) => void;
};

export default ChatFooter;
