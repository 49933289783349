import { textArea } from "@/components/shared/Input";
import { colors } from "@/styles/global.styles";
import { NMSReply } from "@/types/messaging";
import { sleep } from "@/utils";
import { atoms } from "@/utils/helpers/atoms";
import {
  replaceEmojis,
  replaceEmojisInHTMLString,
} from "@/utils/helpers/emoji";
import { css } from "@emotion/react";
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { EditorContent, Extension, Node, useEditor } from "@tiptap/react";
import { useAtom } from "jotai";
import { useImperativeHandle, useRef } from "react";

const Emoji = Node.create({
  name: "emoji",

  inline: true,
  group: "inline",
  atom: false,
  content: "inline*",

  addAttributes() {
    return {
      src: {
        default: "",
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      style: {
        default: null,
      },
      "data-emoji-native": {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "img[data-emoji-native]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // wrapping with a span makes backspace work
    return ["span", ["img", HTMLAttributes]];
  },
});

const KeyboardHandler = Extension.create({
  name: "keyboardHandler",
  addKeyboardShortcuts: () => {
    return {
      Enter: () => true,
      "Mod-Enter": () => true,
      "Shift-Enter": ({ editor }) => {
        return editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock(),
        ]);
      },
    };
  },
});

export type TextAreaAPI = {
  insertEmoji: (s: string) => void;
  setText: (s: string) => void;
  getText: () => string;
  clear: () => void;
  focus: () => void;
  width: number;
};
type TextAreaProps = {
  disabled: boolean;
  handleTyping: (typing: boolean) => void;
  handleSendMessage: (reply?: NMSReply) => void;
  lastInputRef: React.RefObject<string>;
  autoFocus?: boolean;
};
export function TextArea({
  ref,
  disabled,
  handleTyping,
  handleSendMessage,
  lastInputRef,
  autoFocus,
}: TextAreaProps & {
  ref?: React.RefObject<TextAreaAPI | null>;
}) {
  const canPlaceAnotherEmoji = useRef(true);
  const canPlaceAnotherEmojiTimeout = useRef<number>(undefined);

  const currentHTML = useRef<string>(undefined);

  const [sendBtnDisabled, setSendBtnDisabled] = useAtom(
    atoms.messaging.sendBtnDisabled
  );

  const focusableRef = useRef<Promise<void> | null>(null);
  const [reply, setReply] = useAtom(atoms.messaging.messageReply);
  const editor = useEditor(
    {
      extensions: [
        Text,
        Paragraph,
        Emoji,
        Document,
        KeyboardHandler,
        Placeholder.configure({
          placeholder: "Type a message...",
        }),
        History,
      ],
      editable: !disabled,
      onCreate: async ({ editor }) => {
        focusableRef.current = sleep(10);
        await focusableRef.current;

        if (autoFocus) {
          editor.commands.focus();
        }
      },
      onUpdate: ({ editor, transaction }) => {
        if (!transaction.docChanged) return;

        setSendBtnDisabled(editor.isEmpty);

        const previousHTML = currentHTML.current;

        let content = editor.getHTML();
        currentHTML.current = content;

        const isTyping =
          currentHTML.current.length >= (previousHTML?.length || 0);
        handleTyping(isTyping);

        const [replacedEmojis, didReplace] = replaceEmojis(content);
        if (didReplace) {
          if (canPlaceAnotherEmoji.current) {
            // these two commands cannot be `chain()`ed
            editor.commands.setContent(replacedEmojis, false, {
              preserveWhitespace: true,
            });

            const endPos = transaction.selection.anchor;
            editor.commands.focus(endPos);

            canPlaceAnotherEmoji.current = false;
            clearTimeout(canPlaceAnotherEmojiTimeout.current);
            canPlaceAnotherEmojiTimeout.current = +setTimeout(() => {
              canPlaceAnotherEmoji.current = true;
            }, 10);
          } else {
            // avoid duplicate emojis being inserted at once when using the native (windows) emoji picker
            // discord also had this issue
            const idxOfLastInput = content.lastIndexOf(lastInputRef.current);
            const first = content.substring(0, idxOfLastInput);
            const last = content.substring(
              idxOfLastInput + lastInputRef.current.length
            );
            editor.commands.setContent(first + " " + last, false, {
              preserveWhitespace: true,
            });

            const endPos =
              transaction.selection.anchor - lastInputRef.current.length;
            editor.commands.focus(endPos);
          }
        }

        content = editor.getHTML();
        currentHTML.current = content;
      },
    },
    [disabled]
  );

  const innerTextAreaRef = useRef<HTMLDivElement>(null!);

  useImperativeHandle(ref, () => ({
    getText: () =>
      currentHTML.current
        ? replaceEmojisInHTMLString(currentHTML.current).trimEnd()
        : "",
    setText: (s: string) => {
      if (!editor) return;
      editor.commands.clearContent(true);
      editor.commands.insertContent(s);
    },
    insertEmoji: (s: string) => {
      if (!editor) return;
      editor.commands.insertContent(s + " ");
    },
    clear: () => {
      if (!editor) return;
      editor.commands.clearContent(true);
    },
    focus: async () => {
      if (!editor) return;
      await focusableRef.current;
      editor.commands.focus();
    },
    get width() {
      return innerTextAreaRef.current.offsetWidth;
    },
  }));

  return (
    <EditorContent
      ref={innerTextAreaRef}
      editor={editor}
      disabled={disabled}
      onKeyDown={(e) => {
        if (!e.shiftKey && e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          if (!sendBtnDisabled) {
            handleSendMessage(reply || undefined);
            if (reply) {
              setReply(null);
            }
          }
        }
      }}
      onInput={(e) => {
        lastInputRef.current = (e.nativeEvent as InputEvent).data || "";
      }}
      css={textAreaCss}
    />
  );
}

const textAreaCss = css([
  textArea,
  {
    background: "transparent",
    minHeight: "28px",
    margin: `calc((3em - 28px) / 2) 0`,
    marginLeft: "0.25em",
    "& *": {
      outline: "none",
      border: "none",
    },
    "& p": {
      margin: "0",
    },
    // display placeholder
    "& [data-placeholder]::before": {
      content: "attr(data-placeholder)",
      color: colors.secondaryTextColor,
      float: "left",
      height: "0",
      pointerEvents: "none",
    },
  },
]);
