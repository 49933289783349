import twitterSpriteSheetURL from "@/assets/emoji/twitter-emoji-spritesheet-64.webp";
import twitterData from "@emoji-mart/data/sets/15/twitter.json";
import { FrequentlyUsed, init, SearchIndex } from "emoji-mart";
import emojiRegexFunction from "emoji-regex";
import replace from "string-replace-to-array";

export const frequentlyUsed = FrequentlyUsed as unknown as {
  add: typeof FrequentlyUsed.add;
  get: ({
    maxFrequentRows,
    perLine,
  }: {
    maxFrequentRows: number;
    perLine: number;
  }) => string[];
  DEFAULTS: typeof FrequentlyUsed.DEFAULTS;
};

export const searchIndex = SearchIndex as unknown as {
  get: (id: string) => EmojiMartItem;
  search(query: string, options?: { maxResults: number }): EmojiMartItem[];
};

type Skin = {
  unified: string;
  native: string;
  x: number;
  y: number;
  shortcodes: string;
};

export type EmojiMartItem = {
  id: string;
  name: string;
  keywords: string[];
  skins: Skin[];
  version: number;
  aliases: string[];
  search: string;
};

// RegExp based on emoji's official Unicode standards
// http://www.unicode.org/Public/UNIDATA/EmojiSources.txt
export const emojiRegex = emojiRegexFunction();

void init({
  // https://github.com/missive/emoji-mart/pull/809#issuecomment-2476719474
  // it needs to be set to twitter both here and when the Picker component is used
  set: "twitter",
  data: twitterData,
});

const dataEmojiNativeAttr = `data-emoji-native`;
export const TRANSPARENT_GIF =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
export function emojiToHTML(emojiData: EmojiMartItem) {
  const emojiSkin = emojiData.skins[0];

  const img = document.createElement("img");
  Object.assign(img.style, {
    display: "inline-block",
    minWidth: "1em",
    minHeight: "1em",
    maxWidth: "1em",
    maxHeight: "1em",
    backgroundImage: `url(${twitterSpriteSheetURL})`,
    backgroundSize: `${100 * twitterData.sheet.cols}% ${100 * twitterData.sheet.rows}%`,
    backgroundPosition: `${(100 / (twitterData.sheet.cols - 1)) * emojiSkin.x}% ${(100 / (twitterData.sheet.rows - 1)) * emojiSkin.y}%`,
    outline: "none",
    border: "none",
  } satisfies Partial<CSSStyleDeclaration>);
  img.src = TRANSPARENT_GIF;
  img.setAttribute(dataEmojiNativeAttr, emojiSkin.native);

  return img.outerHTML;
}

export function replaceEmojis(text: string): [string, didReplace: boolean] {
  let matched = false;
  const emojiHTML = replace(text, emojiRegex, (nativeEmoji, ...args) => {
    matched = true;

    const index = args.at(-2) as number;

    if (
      text.substring(index - dataEmojiNativeAttr.length - 2, index - 2) ===
      dataEmojiNativeAttr
    ) {
      // continue;
      return nativeEmoji;
    }

    // the emoji-mart package was patched so that this doesn't return a Promise. Just the emoji data directly
    const emojiData = searchIndex.search(nativeEmoji)[0];

    let emojiHTML: string;
    if (emojiData) {
      emojiHTML = emojiToHTML(emojiData);
    } else {
      emojiHTML = nativeEmoji;
    }

    return emojiHTML;
  }).join("");

  return [emojiHTML, matched];
}

export function replaceEmojisInHTMLString(html: string) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const elems = doc.querySelectorAll<HTMLSpanElement>(
    `[${dataEmojiNativeAttr}]`
  );

  for (const elem of elems) {
    const native = elem.dataset.emojiNative;

    // parentElement since the <img> is wrapped in a <span>
    elem.parentElement!.outerHTML = native || "";
  }

  return doc.body.innerText;
}
