import { VerticalRule } from "@/components/shared/VerticalRule";
import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import { getLocalUser } from "@/utils/helpers/localstorage";
import WebGwContact, { getLoadedContacts } from "@/utils/helpers/WebGwContact";
import { getSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import NmsMessage from "@/utils/messaging/NmsMessage";
import parseNmsToChatMessage from "@/utils/messaging/parseNmsToChatMessage";
import { css } from "@emotion/react";
import { animate } from "motion/react";
import { nanoid } from "nanoid";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { ChatMessage } from "../typings";
import ContactCard from "./ContactCard";
import RichcardMessage from "./RichcardMessage";
import TextMessage from "./TextMessage";

export function RepliedMessage({
  incoming,
  repliedMessage,
}: {
  incoming: boolean;
  repliedMessage: NmsMessage | undefined;
}) {
  const [fallbackToGenericFile, setFallbackToGenericFile] = useState(false);
  const messageIsDeleted =
    !repliedMessage || repliedMessage._isBeingDeleted || repliedMessage.deleted;
  const message = useMemo(() => {
    if (messageIsDeleted) {
      const msgId = nanoid();
      return {
        msgId,
        originalMsgId: msgId,
        textMessage: "Message was deleted",
        direction: incoming ? "In" : "Out",
        time: new Date().toISOString(),
        deleted: true,
      } satisfies ChatMessage;
    } else {
      return parseNmsToChatMessage(repliedMessage, fallbackToGenericFile);
    }
  }, [repliedMessage, fallbackToGenericFile]);

  const messageComponentWrapperRef = useRef<HTMLDivElement>(null!);
  // since the reply is scaled down when it's not a text message, the height need to be set right after (width can be kept as is otherwise items others than media like application files or contact card wont show up properly)
  useLayoutEffect(() => {
    resizeReply();
  }, [messageIsDeleted, fallbackToGenericFile]);

  if (!message) return null;

  let MessageComponent:
    | typeof TextMessage
    | typeof RichcardMessage
    | typeof ContactCard
    | undefined;
  if (message.textMessage) {
    MessageComponent = TextMessage;
  } else if (message.richcardMessage) {
    MessageComponent = RichcardMessage;
  } else if (message.contactCard) {
    MessageComponent = ContactCard;
  }

  const resizeReply = () => {
    const wrapper = messageComponentWrapperRef.current;

    if (messageIsDeleted) {
      wrapper.style.width = "";
      wrapper.style.height = "";
      return;
    }

    if (MessageComponent === TextMessage) return;

    const { height } = wrapper.firstElementChild!.getBoundingClientRect();
    wrapper.style.height = `${height}px`;
  };

  // Some components are rendered asynchronously (contact card needs to fetch its vcard before rendered), make sure to resize after
  const handleOnRendered = () => {
    resizeReply();
  };

  const handleFallbackToGenericFile = () => {
    setFallbackToGenericFile(true);
  };

  if (!MessageComponent) return null;

  return (
    <div css={{ overflow: "hidden", margin: "0.25em 0" }}>
      <RepliedToText incoming={incoming} repliedMessage={repliedMessage} />
      <div
        ref={messageComponentWrapperRef}
        css={{
          zIndex: 1,
          backgroundColor: "transparent",
          position: "relative",
          [incoming ? "marginLeft" : "marginRight"]: "0.75em",
          color: colors.secondaryTextColor,
          "& > *": {
            pointerEvents: "none",
            ...(MessageComponent !== TextMessage && {
              "&:not(.reply-rule)::after": {
                content: "''",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(rgba(190, 190, 190, 0.5), rgba(190, 190, 190, 0.25))",
              },
              "&:not(.reply-rule)": {
                scale: "0.75",
                opacity: "0.5",
                transformOrigin: incoming ? "top left" : "top right",
              },
            }),
          },
          cursor: "pointer",
        }}
        onClick={() => {
          findMessageAndScrollIntoView(message.msgId);
        }}
      >
        <MessageComponent
          message={message}
          removeTopMargin
          isLastMessage={false}
          roundBorderTop
          roundBorderBottom
          direction={incoming ? "Out" : "In"}
          onRendered={handleOnRendered}
          onFallbackToGenericFile={handleFallbackToGenericFile}
          isReply
          showReplyMessage={true}
        />
        <VerticalRule
          className="reply-rule"
          css={{
            opacity: "0.7",
            position: "absolute",
            [incoming ? "left" : "right"]: "-0.75em",
            bottom: "1px",
            backgroundColor: "#696C6F",
            margin: "0",
            height: "calc(100% - 2px)",
            width: "0",
            boxShadow: incoming
              ? `1px 0 1px 1px #696C6F, 1.5px 0 1.5px 1px #696C6F`
              : `-1px 0 1px 1px #696C6F, -1.5px 0 1.5px 1px #696C6F`,
          }}
        />
      </div>
    </div>
  );
}

const repliedToTextCSS = css({
  color: colors.secondaryTextColor,
  fontSize: "12px",
  opacity: "0.75",
  paddingBottom: "0.25em",
  pointerEvents: "none",
  userSelect: "none",
});
const repliedToTextCSSOutgoing = css([repliedToTextCSS, { textAlign: "end" }]);

function RepliedToText({
  incoming,
  repliedMessage,
}: {
  incoming: boolean;
  repliedMessage: NmsMessage | undefined;
}) {
  if (incoming) {
    return <IncomingRepliedToText />;
  }

  return <OutgoingRepliedToText repliedMessage={repliedMessage} />;
}

function IncomingRepliedToText() {
  const conversationIsGroupChat = useMemo(
    () => !!getSelectedConversation()?.getIsGroupChat(),
    []
  );

  return (
    <p css={repliedToTextCSS}>
      Replied{conversationIsGroupChat ? "" : " to you"}
    </p>
  );
}

function OutgoingRepliedToText({
  repliedMessage,
}: {
  repliedMessage: NmsMessage | undefined;
}) {
  const selfReply = useMemo(() => {
    const from = repliedMessage?.From;
    if (!from) return false;

    const contacts = getLoadedContacts();

    const contact =
      contacts?.findWithNumber(from) || WebGwContact.fromPhoneNumber(from);

    const localUser = getLocalUser();

    return isSamePhoneNumber(localUser, contact?.getMainPhoneNumber());
  }, []);

  return (
    <p css={repliedToTextCSSOutgoing}>
      You replied{selfReply ? " to yourself" : ""}
    </p>
  );
}

function findMessageAndScrollIntoView(messageId: string) {
  // highlight the element that was replied to
  const repliedToElem = document.querySelector(
    `.chat [data-message-id='${messageId}']`
  );
  if (!repliedToElem) {
    console.error(
      "Replied to element not found",
      `[data-message-id='${messageId}']`
    );
    return;
  }

  console.log("Scrolling to replied to element", repliedToElem);

  repliedToElem.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
  animate(
    repliedToElem,
    {
      filter: ["brightness(1)", "brightness(1.5)", "brightness(1)"],
    },
    {
      duration: 1.5,
      ease,
    }
  );
}
