import { usePrevious } from "@/utils/hooks/usePrevious";
import { useSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import { keyframes } from "@emotion/react";
import { memo } from "react";
import { footerPaddingHeight } from "../../ChatFooter/ChatFooterUtils";

const slideIn = keyframes({
  "0%": {
    transform: "translateY(100%)",
    opacity: "0",
  },
  "100%": {
    transform: "translateY(0)",
    opacity: "0.75",
  },
});

const slideOut = keyframes({
  "0%": {
    transform: "translateY(0)",
    opacity: "0.75",
  },
  "100%": {
    transform: "translateY(100%)",
    opacity: "0",
  },
});

const maxComposerCount = 3;
export default memo(function TypingIndicator() {
  const composers = useSelectedConversation()?.composers;
  const prevComposers = usePrevious(composers);

  const hasComposers = composers && composers.length > 0;

  const composersToShow = hasComposers ? composers : prevComposers;
  const composerNames = composersToShow
    ? composersToShow.length > maxComposerCount
      ? "many people"
      : composersToShow
          .map(([contact]) => contact.noNameReturnPhoneNumber())
          .join(", ")
    : "";

  return (
    <div
      css={{
        userSelect: "none",
        pointerEvents: "none",
        position: "relative",
        fontSize: "0.66rem",
        animation: `${hasComposers ? slideIn : slideOut} 0.25s ease forwards`,
        color: "var(--msg-text-color)",
        padding: `0 0 0 4px`,
        width: `calc(100% - ${footerPaddingHeight} * 2)`,
        height: footerPaddingHeight,
        maxHeight: footerPaddingHeight,
      }}
    >
      <span
        css={{
          margin: "0 0.5em",
        }}
      >
        {!composerNames ? null : (
          <span>
            <b>{composerNames} </b>
            {hasComposers && composers && composers.length > 1
              ? "are"
              : "is"}{" "}
            typing
          </span>
        )}
      </span>
      <span>
        {!composerNames ? null : (
          <>
            <Dot />
            <Dot />
            <Dot />
          </>
        )}
      </span>
    </div>
  );
});

const blink = keyframes({
  "0%, 100%": {
    opacity: 0.2,
  },
  "20%": {
    opacity: 1,
  },
});

function Dot() {
  return (
    <span
      css={{
        display: "inline-block",
        width: "0.55em",
        height: "0.55em",
        marginRight: "2px",
        borderRadius: "50%",
        background: "#777777",
        animation: `${blink} 1.4s ease-out infinite`,
        animationFillMode: "both",
        ":nth-of-type(2)": {
          animationDelay: "-1.1s",
        },
        ":nth-of-type(3)": {
          animationDelay: "-0.9s",
        },
      }}
    ></span>
  );
}
